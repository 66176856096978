import './Canvas.css'
import React, {EventHandler, SyntheticEvent, useEffect, useRef} from 'react'
import { kvObj } from '../kvObj';

interface CanvasProps {
    map: kvObj;
    grassy: boolean;
    map_click: Function;
}

interface Coords {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    code: string;
}
const spots= [
  { code: 'O2', x: 0.14, y: 2, height: 60 },
  { code: '36', x: 0.14, y: 58, height: 60 },
  { code: '34', x: 0.14, y: 116, height: 60 },
  { code: '32', x: 0.14, y: 170, height: 60 },
  { code: '30', x: 0.14, y: 224, height: 60 },
  { code: '28', x: 0.14, y: 278, height: 60 },
  { code: '26', x: 0.14, y: 332, height: 60 },
  { code: '24', x: 0.14, y: 386, height: 60 },
  { code: '22', x: 0.14, y: 440, height: 60 },
  { code: '35', x: 0.554, y: 58, height: 60 },
  { code: '33', x: 0.554, y: 116, height: 60 },
  { code: '31', x: 0.554, y: 170, height: 60 },
  { code: '29', x: 0.554, y: 224, height: 60 },
  { code: '27', x: 0.554, y: 278, height: 60 },
  { code: '25', x: 0.554, y: 332, height: 60 },
  { code: '23', x: 0.554, y: 386, height: 60 },
  { code: '21', x: 0.554, y: 440, height: 60 },
  { code: '2', x: 0.138, y: 625, height: 68.5 },
  { code: '4', x: 0.138, y: 693, height: 68 },
  { code: '6', x: 0.138, y: 761, height: 68 },
  { code: '8', x: 0.138, y: 829, height: 68 },
  { code: '10', x: 0.138, y: 897, height: 68 },
  { code: '12', x: 0.138, y: 965, height: 68 },
  { code: '14', x: 0.138, y: 1033, height: 68 },
  { code: '16', x: 0.138, y: 1101, height: 68 },
  { code: '18', x: 0.138, y: 1169, height: 68 },
  { code: '20', x: 0.138, y: 1237, height: 68 },
  { code: '1', x: 0.554, y: 625, height: 68 },
  { code: '3', x: 0.554, y: 693, height: 68 },
  { code: '5', x: 0.554, y: 761, height: 68 },
  { code: '7', x: 0.554, y: 829, height: 68 },
  { code: '9', x: 0.554, y: 897, height: 68 },
  { code: '11', x: 0.554, y: 965, height: 68 },
  { code: '13', x: 0.554, y: 1033, height: 68 },
  { code: '15', x: 0.554, y: 1101, height: 68 },
  { code: '17', x: 0.554, y: 1169, height: 68 },
  { code: '19', x: 0.554, y: 1237, height: 68 },
{ code: 'O4', x: 0.138, y: 1300, height: 68 },
{ code: 'O6', x: 0.138, y: 1368, height: 68 },
{ code: 'O3', x: 0.554, y: 1300, height: 68 },
{ code: 'G1', x: 0.075, y: 338, height: 60 },
{ code: 'G2', x: 0.075, y: 398, height: 60 },
{ code: 'G3', x: 0.075, y: 458, height: 60 },
{ code: 'G4', x: 0.075, y: 518, height: 60 },
{ code: 'G5', x: 0.075, y: 578, height: 60 },
{ code: 'G6', x: 0.075, y: 638, height: 60 },
{ code: 'G7', x: 0.075, y: 698, height: 60 },
{ code: 'G8', x: 0.075, y: 758, height: 60 },
{ code: 'G9', x: 0.075, y: 818, height: 60 },
{ code: 'G10', x: 0.075, y: 878, height: 60 },
{ code: 'G11', x: 0.075, y: 938, height: 60 },
{ code: 'G12', x: 0.075, y: 998, height: 60 }];


let assignments:  kvObj={}
let clickZones:Coords[]=[];
let grassy=false;
let mapClick:Function;

const handleclick: EventHandler<any>=(evt: SyntheticEvent) => {
  let e=evt.nativeEvent as PointerEvent;
  let x=e.offsetX;
  let y=e.offsetY
   const found=clickZones.filter(cz=> {  return (cz.x1<x && cz.x2>x && cz.y1<y && cz.y2>y)})
   mapClick(assignments[found[0].code])
}

const getLines: Function=( ctx: CanvasRenderingContext2D, text: string, width:number, height:number)=> {
   const Lines:string[]=[]
   const splitText=text.split(" ")
   let curline=""
   splitText.forEach(word=> {
      if (ctx.measureText(curline+" "+word).width>width) {
         Lines.push(curline);
         curline=word;
      }
      else {
        curline=curline+" "+word
      }
   })
   if (curline.length>0) {
     Lines.push(curline);
   }
   return Lines
}

const hasGrassys: Function=() => {
   let assigned:kvObj={}
   let grassy=false;
   let grassies=Object.keys(assignments).filter(k=> {return (k.indexOf("G")>-1)})
   return (grassies.length>0)
}

const drawColor: Function=(ctx: CanvasRenderingContext2D, categories: string, x:number, y: number, width: number, height:number ) => {
  const cats=categories.split(",")//divide the category string into array elements (for case of comma separated)
  width=width/cats.length
  ctx.fillStyle=getComputedStyle(document.documentElement).getPropertyValue(`--map-color-${cats[0]}`)  || "#4c4545"
  ctx.fillRect(x, y,width,height )
  if (cats.length>1) {
     ctx.fillStyle=getComputedStyle(document.documentElement).getPropertyValue(`--map-color-${cats[1].trim()}`)  || "#c7c7c7"
     ctx.fillRect(x+width, y,width,height )
  }
}

const writeText: Function=(ctx: CanvasRenderingContext2D, text: string, x:number, y: number, width: number, height:number )=> {

}

const drawSpots: Function=(ctx: CanvasRenderingContext2D, innerWidth: number, grassy: boolean)=> {
  const width=innerWidth*.33 
  ctx.font=getComputedStyle(document.documentElement).getPropertyValue(`--map-text`)
  //ctx.font = "bold 16px Sans-serif";
  ctx.fillStyle="black";
  ctx.textAlign="center";
  let spots_to_render=spots.filter(s=> {
    return ((grassy && s.x<.5) || (!grassy && (s.code.indexOf("G")==-1)))
  })
  clickZones=[]
  spots_to_render.forEach(spot=> {
    let spot_x=!grassy?spot.x: (spot.code.indexOf("G")==-1?.55:spot.x)
    ctx.strokeRect(spot_x*innerWidth, spot.y,width,spot.height )
    const label=assignments[spot.code]?assignments[spot.code].name:"";
    const category=assignments[spot.code]?assignments[spot.code].categories:spot.code;
    drawColor(ctx,category, spot_x*innerWidth, spot.y,width,spot.height )
    clickZones.push({x1: spot_x*innerWidth , y1: spot.y ,x2: spot_x*innerWidth+width, y2: spot.y+spot.height, code: spot.code })
    ctx.fillStyle="white";
    const lines:string[]=getLines(ctx,label, width, spot.height);
    let texty=spot.y+19;
    switch (lines.length){
        case 1: texty+=spot.height/4; 
                break;
        case 2: texty+=spot.height/8; 
    }        
    ctx.fillStyle=getComputedStyle(document.documentElement).getPropertyValue(`--map-text-color-${category}`)
    lines.forEach(line=> {
      ctx.fillText(line,spot_x*innerWidth+(width/2), texty, width )
      texty+=17;  
    })
   })
}

const Canvas =( { map, grassy, map_click}: CanvasProps) => {
   assignments=map
   mapClick=map_click  
   const canvasRef=useRef<HTMLCanvasElement>(null)   
   useEffect(()=> {
      if (canvasRef.current) {
        const canvas=canvasRef.current;
        //canvas.style.position="absolute"
        const image=new Image();
        image.src="assets/map.svg"
        let img_width=image.width;
        let img_height=image.height;
        let hw_ratio=img_height/img_width;
        let display_width=image.width
        let img_xoffset=0;
        const innerWidth=document.getElementsByClassName("ion-page")[0].clientWidth
        if (img_width>innerWidth ) {
          img_xoffset=grassy? 20 : image.width*.29           
          img_width=image.width*.66666
          display_width=innerWidth
        }     
        //canvas.style.left=`-200px`        
        const context=canvas.getContext('2d');
        canvas.height=1400
        image.onload = () => {
            const scalex=(image.width-400)/innerWidth
            //console.log('innerwidth:', window.innerWidth, " imagewidth:", img_width, " xoffset",img_xoffset)
            context!.drawImage(image,img_xoffset, 0, img_width, image.height+50,0,0, display_width, image.height)
            drawSpots(context!, innerWidth, grassy);
            //console.log(image.width, ",", image.height)
          };
      }
   })
   return <canvas ref={canvasRef} height={window.innerHeight} width={window.innerWidth} onClick={handleclick}/>   
}

export default Canvas
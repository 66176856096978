import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonBadge, IonFab, IonFabButton, IonIcon, IonModal, IonButton, IonButtons, IonRefresher, IonRefresherContent, RefresherEventDetail, IonToast, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { chevronBack, chevronForward, closeCircle } from 'ionicons/icons';
import  { FC, useState, useEffect, useRef} from 'react';
import Canvas from '../components/Canvas';
import { kvObj } from '../kvObj';

import './Tab1.css';

const getMap=async ()=> {
   let map= new Promise( (res, rej) => {
        fetch("https://mktmap.vargas.workers.dev/map").then(function(response) {
          // The response is a Response instance.
          // You parse the data into a useable format using `.json()`
          return response.json();
        }).then(function(data) {
          // `data` is the parsed version of the JSON returned from the above endpoint.=                    
          res(data)
        });
   })
   let result=(await map);
   return result;
}


const getVendorDesc=async (name:string)=> {
  let vendor_desc:Promise<kvObj>= new Promise( (res, rej) => {
       let vendor_key=name.replace(/\s/g,"");
       //console.log('vendor key:', vendor_key)
       fetch(`https://mktmap.vargas.workers.dev/vendor/${vendor_key}`).then(function(response) {
         // The response is a Response instance.
         // You parse the data into a useable format using `.json()`
         return response.json();
       }).then(function(data) {
         // `data` is the parsed version of the JSON returned from the above endpoint.
         res(data)
       });
  })
  let result:kvObj=(await vendor_desc);
  return result;
}




const Tab1: React.FC = () => {
  const [map, setMap]=useState({} as kvObj);
  const [hasGrassy, setHasGrassy]=useState(false); //grassy vendors exist in map
  const [grassy, setGrassy]=useState(false);       //grassy vendor view is active
  const [vendor, setVendor]=useState(""); 
  const [vendor_desc, setVendorDesc]=useState("");
  const [vendor_real_name, setVendorRealName]=useState("");
  const [logo_suffix, setLogoSuffix]=useState("");
  const [showToast, setShowToast]=useState(false);
  const [mapUpdated, setMapUpdated]=useState("");
  const modal=useRef<HTMLIonModalElement>(null);
  const page = useRef(null);
  const [presentingModal, setPresentingModal] = useState<HTMLElement | null>(null);
  let width=window.innerWidth;
  let height=0
  let logoSuffix="";
  const getMapVal=()=>{
     getMap().then(res=>{
        //if there is a G_ map value, there is a grassy vender
        setHasGrassy(Object.keys(res as kvObj).filter( k=> {return (k.indexOf("G")>-1)}).length>0)
        setMap(res as kvObj)
        setMapUpdated(`Updated ${(res as kvObj)["updated"]}`)
        setShowToast(true);
        console.log(mapUpdated)
     })
  }

  const handleRefresh=async(event: CustomEvent<RefresherEventDetail>) =>{
      let res= await getMap();
      setHasGrassy(Object.keys(res as kvObj).filter( k=> {return (k.indexOf("G")>-1)}).length>0)
      let result=res as kvObj
      setMap(result)
      setMapUpdated(`Updated ${result["updated"] as string}.`)
      console.log(`UPDATED `)
      setTimeout( function()  {  //add some wait time to refresh so that user doesn't assume nothing happened.
        event.detail.complete() 
        setShowToast(true);
        setTimeout(function() {
            getMapVal()
        },10000000)
      }, 1000);    
  }
   

  const mapClick=(vendor_details: kvObj)=> {
     if (vendor_details) {
      setVendor(vendor_details["name"])
      setVendorDesc("");
      getVendorDesc(vendor_details["name"]).then( res  => {
        setVendorDesc(res.description)
        setVendorRealName(res.real_name);
      })
      modal.current?.present();
     }
  }

  const dismissModal=()=> {
    modal.current?.dismiss();
  }
  useEffect(()=>{
    getMapVal()
  },[]);
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
          <IonTitle><img className='logo' src={(getComputedStyle(document.documentElement).getPropertyValue(`--ion-color-mode`)=="dark")?"/assets/icon/favicon_alpha_dm.png":"/assets/icon/favicon_alpha.png"}></img><div className='fl'>Ashe County Farmers Market</div></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen >
        <IonToast position='top' isOpen={showToast} message={mapUpdated} duration={3000} onDidDismiss={() => setShowToast(false)}></IonToast> 
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh} >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
          {  grassy && hasGrassy  && <IonFab slot="fixed" horizontal='end' >
              <IonBadge class="shelter" onClick={ ()=> {setGrassy(false)}}>ALL SHELTER VENDORS</IonBadge>
            </IonFab>}
          { !grassy && hasGrassy && <IonFab slot="fixed" horizontal='start' >
              <IonBadge class="grassy" onClick={ ()=> {setGrassy(true)}}>GRASSY VENDORS</IonBadge>
            </IonFab>
            }
          <Canvas grassy={grassy} map={map} map_click={mapClick}></Canvas>
          <IonModal className='modal'   ref={modal} trigger="open-modal" initialBreakpoint={0.75} breakpoints={[0, 0.85]} presentingElement={presentingModal!}>
          
          <IonHeader className='vendor-header'>
            <IonToolbar>
              <IonCardTitle>{vendor}</IonCardTitle>
              <IonCardSubtitle>{vendor_real_name}</IonCardSubtitle>
              <IonButtons slot="end">
                <IonButton onClick={() => dismissModal()}><IonIcon icon={closeCircle}></IonIcon></IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="vendor-details">
            <div className="vendor-details">{vendor_desc}</div>
            </IonContent>
          </IonModal>  
          
        </IonContent>
      </IonPage>
    );
  }


export default Tab1;
